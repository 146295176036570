@import "../../styles/variables";

.container {
  margin-top: 32px;
  margin-right: 120px;

  @media only screen and (max-width: $mobile-breakpoint) {
    margin-right: 0;
    margin-top: 0;
  }

  @media only screen and (max-width: $tablets-breakpoint) {
    margin-top: 0;
    margin-right: 0;
  }

  p {
    font-size: 12px;
    line-height: 10px;
    font-family: Poppins, sans-serif;
    color: $dark-grey-500;
  }
}
