@import '../../styles/variables';

@keyframes ShowPopup {
    0% {
     opacity: 0;
     transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(-0);
    }
  }

  @keyframes ShowPopupOverlay {
    0% {
     opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

.overlay {
    position: absolute;
    z-index: $popup-zindex;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, .7);
    animation: ShowPopupOverlay 0.3s ease-in 0s 1 both;
}

.popupSubcribe {
    position: fixed;
    top: 168px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 24px 32px 24px;
    width: 320px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 16px 80px rgba(0, 0, 0, 0.07), 0 10.3704px 46.8519px rgba(0, 0, 0, 0.0531481), 0 6.16296px 25.4815px rgba(0, 0, 0, 0.0425185), 0 3.2px 13px rgba(0, 0, 0, 0.035), 0 1.3037px 6.51852px rgba(0, 0, 0, 0.0274815), 0 0.296296px 3.14815px rgba(0, 0, 0, 0.0168519);
    animation: ShowPopup 0.3s ease-in 0.2s 1 both;

    h2 {
        color: $costumizable-red;
        line-height: 24px;
        font-size: 16px;
        font-weight: 700;
        font-family: Oswald, sans-serif;
    }

    p {
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        max-width: 415px;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            width: 100%;
            margin-bottom: 8px;

            p {
                margin: 0;
                font-size: 10px;
                color: $time-red;
            }
        }

        input {
            background: transparent;
            border: 1px solid #C4C4C4;
            border-radius: 8px;
            padding: 8px 10px 8px 16px;
            margin-top: 8px;
            width: 100%;
            size: 12px;
            line-height: 20px;
            color: $dark-grey;
            max-width: 315px;

            &:focus {
                color: black;
            }
        }

        button {
            background-color: $costumizable-red;
            color: white;
            border-radius: 8px;
            padding: 8px 48px;
            width: 158px;
            border: none;
            margin-top: 8px;
            cursor: pointer;

            &:disabled {
                opacity: 0.5;
                cursor:auto;
            }
        }
    }

    @media screen and (min-width: $tablets-breakpoint) {
        width: 630px;
    }

    .closePopup {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor:pointer;
    }
}


.popupResumeVideo {
    position: fixed;
    bottom: 0;
    height: 132px;
    width: 100%;
    padding: 0 36px;
    background-color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    z-index: $popup-zindex - 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 16px 80px rgba(0, 0, 0, 0.07), 0 10.3704px 46.8519px rgba(0, 0, 0, 0.0531481), 0 6.16296px 25.4815px rgba(0, 0, 0, 0.0425185), 0 3.2px 13px rgba(0, 0, 0, 0.035), 0 1.3037px 6.51852px rgba(0, 0, 0, 0.0274815), 0 0.296296px 3.14815px rgba(0, 0, 0, 0.0168519);
    animation: ShowPopup 0.3s ease-in 0.2s 1 both;

        p {
            margin: 0 auto;
            text-align: left;
            padding: 16px;
            max-width: 380px;
        }

        .buttonsWrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                height: 36px;
                width: 140px;
                color: white;
                border-radius: 8px;
                cursor: pointer;

                &:last-of-type {
                    background-color: $secondary-confirmation;

                    &:hover {
                        background-color: $secondary-confirmation-hover;
                    }
                }

                &:first-of-type {
                    background-color: $primary-black;
                    margin-right: 8px;

                    &:hover {
                        background-color: #000000;
                    }
                }
            }
        }

    @media screen and (min-width: $tablets-breakpoint) {
        flex-direction: row;
        width: 100%;
        max-width: 636px;
        height: 80px;
        bottom: 64px;
        left: 24px;
        border-radius: 10px;
    }
}
