
.menuButtonContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menuContainer {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  width: 320px;
  height: 248px;
  bottom: 36px;
  right: 30px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  padding: 24px;
  flex-direction: row-reverse;

  .hideButton {
    width: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 24px;
    margin: 1px 5px 0 0;

    & > svg {
      height: 24px;
      width: 24px;
    }

    & > span {
      color: black;
      font-size: 12px;
      line-height: 20px;
      font-weight: 700;
      margin-left: 8px;
    }
  }

  .playbackRates {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: default;

    .playbackRate {
      height: 24px;
      color: black;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      input[type="radio"] {
        appearance: none;
        background-color: #fff;
        margin: 0 16px 0 0;
        width: 24px;
        height: 24px;
        border: 2px solid black;
        border-radius: 50%;
        cursor: pointer;
        display: grid;
        place-content: center;
      }
      input[type="radio"]::before {
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: black;
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }
    }
  }
}

.hidden {
  display: none;
}
