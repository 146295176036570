@import "../../styles/variables";

.wrapper {
  margin: 16px 20px;
  max-height: 120px;

  .container {
    max-width: $mobile-content-max-width;

    @media screen and (min-width: $desktop-breakpoint) {
      max-width: $desktop-leaderboard-max-width;
      min-height: 20px;
      max-height: 120px;
    }

    .adBanner {
      width: 100%;
      height: auto;
      max-height: 90px;
    }

    .adLink {
      cursor: pointer;
    }
  }
}
