
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 48px 0 0;

  .code {
    font-size: 86px;
    line-height: 110%;
    margin: 32px 0 12px;
  }

  .message {
    font-size: 64px;
    line-height: 110%;
    margin: 32px 0 12px;
    text-transform: uppercase;

  }
}
