@import "../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  padding-left: 20px;
  box-shadow: 0 1px 0 $primary-grey;

  & > img {
    width: auto;
    height: 100%;
    max-height: calc(100% - 16px);
    margin: 8px 0;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    padding-left: 32px;
  }
}

.footer {
  .desktopFooter {
    height: 189px;
    border-top: 1px solid $dark-grey;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $tablets-breakpoint) {
      display: none;
    }
  }

  .mobileFooter {
    display: none;
    @media only screen and (max-width: $tablets-breakpoint) {
      height: 189px;
      border-top: 1px solid $dark-grey;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  @media only screen and (max-width: $mobile-breakpoint) {
    height: 489px;
  }

  @media only screen and (max-width: $tablets-breakpoint) {
    height: 450px;
  }

  .logo {
    margin-right: 120px;
  }
}
