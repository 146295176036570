@import "src/layout/layout.module.scss";
@import "../../styles/variables";
@import "../../styles/mixins";

$max-desktop-header-height: 140px;
$max-page-content-height: 520px;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $desktop-content-max-width;
  margin: 0 auto;
  min-height: calc(100vh - 96px);
}

.pageContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  min-height: $max-page-content-height;

  @media screen and (max-width: $desktop-breakpoint) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: $desktop-breakpoint) {
    flex-direction: row;
    justify-content: center;
  }
}

.videoColumn {

  @media only screen and (max-width: $mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.descriptionContainer {
  width: 100%;

  @media screen and (min-width: $desktop-breakpoint) {
    max-width: $wide-video-max-width;
  }

  @media screen and (max-width: $desktop-breakpoint) {
    max-width: $desktop-video-width;
  }

  @media screen and (max-width: $tablets-breakpoint) {
    max-width: $tablets-video-max-width;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 100%;
  }
}

.playerContainer {
  position: relative;
  width: $wide-video-max-width;
  height: $wide-video-max-width;
  max-width: $wide-video-max-width;
  max-height: $wide-video-max-width;

  @media screen and (max-width: $desktop-breakpoint) {
    width: $desktop-video-width;
    height: $desktop-video-width;
    max-width: $desktop-video-width;
    max-height: $desktop-video-width;
  }

  @media screen and (max-width: $tablets-breakpoint) {
    width: $tablets-video-width;
    height: $tablets-video-width;
    max-width: $tablets-video-max-width;
    max-height: $tablets-video-max-width;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100vw;
    height: calc(100vw - 40px);
    max-width: unset;
    max-height: unset;
    padding: 0 20px;
  }
}
