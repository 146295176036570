@import "src/layout/layout.module.scss";
@import "../../styles/variables";
@import "../../styles/mixins";

$max-mobile-header-height: 64px;

.container {
  align-self: center;
  width: calc(100% - $page-elements-horizontal-margin);
  margin: 10px 20px 16px 0;
  text-align: left;

  p, h2 {
    margin: 0;
  }

  h2 {
    line-height: 32px;
    font-size: 24px;
    font-weight: 700;
    font-family: Oswald, sans-serif;
    overflow: hidden;
    max-height: $max-mobile-header-height;
    margin-top: 12px;
  }

  p {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    margin-top: 6px;
    color: $light-black;
  }

  @media screen and (max-width: $tablets-breakpoint) {
    margin: 0;
    width: 100%;
    h2 {
      line-height: 24px;
      font-size: 16px;
    }

    p {
      line-height: 20px;
      font-size: 12px;
      margin-top: 0;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 90%;
    margin: 0 20px;
  }
}
