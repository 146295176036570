@import 'styles/variables';

::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

.App {
  position: relative;
  text-align: center;

  @media screen and (min-width: $tablets-breakpoint) {
    height: 100vh;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    height: 100vh;
  }
}

