@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $primary-black;
    background-color: $primary-black;
  }
}
