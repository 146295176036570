
.playlistControl {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
  }
}
