@import "../../styles/variables";

.container {
  position: absolute;
  left: calc(50% - 128px);
  width: 256px;
  height: 56px;
  background-color: $costumizable-red;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 52px;

  @media only screen and (max-width: $desktop-breakpoint) {
    position: relative;
    left: 0;
    margin-left: -200px;
  }

  @media only screen and (max-width: $tablets-breakpoint) {
    max-width: 321px;
    width: 100%;
    margin: 48px 40px 0 40px;
  }

  p {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
  }
}
