@import "../../styles/variables";

.container {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;

  @media only screen and (max-width: $tablets-breakpoint) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 22px 0;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 22px 0;
  }

  p {
    color: $dark-grey-500;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    font-family: Poppins, sans-serif;
  }
}
