//colors
$light-grey: #F6F6F6;
$dark-grey: #C4C4C4;
$time-red: #EC1C2E;
$dark-grey-500: rgb(115, 115, 115);
$light-black: rgba(26, 26, 26, 1);

// primary-colors
$primary-black: #1A1A1A;
$primary-dark-grey: #737373;
$primary-grey:#C4C4C4;
$primary-light-grey:#F6F6F6;
$primary-white: #FFFFFF;

// secondary-colors
$secondary-confirmation: #16732C;
$secondary-confirmation-hover: #115922;
$secondary-discard: #CB2929;
$secondary-discard-hover: #731616;

//costumizable-colors
$costumizable-red: #EC1C2E;
$costumizable-red-dark: #B21523;

//breakpoints
$mobile-breakpoint: 740px;
$tablets-breakpoint: 950px;
$desktop-breakpoint: 1500px;
$wide-breakpoint: 1920px;

//common layout variables
$page-elements-horizontal-margin: 40px;
$playlist-header-height: 60px;
$header-height: 48px;
$advertisement-height: 122px;
$min-player-width-and-height: 520px;

//mobile layout variables
$mobile-content-max-width: 480px;
$mobile-playlist-width: 330px;

//tablets layout variables
$tablets-video-width: 356px;
$tablets-video-max-width: 356px;
$tablets-playlist-body-min-height: 160px;
$tablets-playlist-width: 356px;
$tablets-home-page-max-width: 1024px;

// desktop layout variables
$desktop-content-max-width: 1366px;
$desktop-video-width: 450px;
$desktop-video-max-width: 500px;
$desktop-page-content-info-width: 306px;
$desktop-leaderboard-max-width: 728px;
$desktop-playlist-width: 450px;
$desktop-content-max-height: calc(100vh - $header-height * 2 - $advertisement-height - 20px);

// wide screen layout variables
$wide-video-max-width: 700px;

//z-index
$popup-zindex: 1000;
