@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  position: relative;
  width: 700px;
  min-height: $min-player-width-and-height;
  align-self: start;
  margin: 0 20px 0 25px;

  @media screen and (max-width: $desktop-breakpoint) {
    width: calc($desktop-playlist-width - 8px);
  }

  @media screen and (max-width: $tablets-breakpoint) {
    width: calc($tablets-playlist-width - 8px);
    margin: 0 0 0 20px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    margin: 0 20px;
    padding: 0 20px;
  }
}

.playlistItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5px);
  margin-bottom: 7px;
  cursor: pointer;
  border-bottom: 1px solid $primary-grey;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 0;
  }

  .itemDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    min-height: 56px;
    color: $primary-black;

    h4, p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      text-align: left;
      text-overflow:ellipsis;
      white-space:nowrap;
      display:inline-block;
      font-family: Poppins, sans-serif;
      overflow: hidden;
    }

    h4 {
      font-weight: 700;
      margin-top: 5px;
    }

    p {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 1px;
    }

    @media screen and (max-width: $tablets-breakpoint) {
      h4 {
        font-size: 14px;
      }
    }

    @media screen and (max-width: $mobile-breakpoint) {
      h4 {
        font-size: 12px;
      }
    }

    &Watched {
      h4, p {
        color: $primary-dark-grey;
      }
    }

    &CurrentVideo {
      h4, p {
        color: var(--highlightColor);
      }
    }
  }
}

.playlistItemDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  font-size: 18px;
  color: $dark-grey-500;
  margin: 0 0 2px 0;
  height: 20px;

  .description {
    flex: 1;
  }

  .totalTime {
    margin-left: 8px;
  }
}

.playlistHeader {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 10px 0;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 700;
      color: black;
      width: 100%;
      height: 24px;
      overflow: hidden;

    }
  }

  p {
    font-size: 10px;
    line-height: 30px;
    color: black;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    p {
      margin: 0;
    }

    .title {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 700;
        color: $dark-grey-500;
        width: 100%;
        height: 24px;
        overflow: hidden;

      }
    }
   }
}

.playlistBody {
  margin-bottom: 120px;
  @include custom-scrollbar;
}

.pagination {
  display: flex;
  justify-content: center;

  p {
    padding: 4px;
    margin: 64px 8px 8px 0;
    width: 32px;
    height: 32px;

    @media screen and (min-width: $tablets-breakpoint) {
      margin: 64px 8px 8px 0;
      width: 24px;
      height: 24px;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      margin: 48px 8px 8px 0;
      width: 24px;
      height: 24px;
    }
  }
}

.paginationItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  margin-right: 8px;
  border-radius: 4px;
  border: none;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
  margin-top: 64px;

  &Current  {
    background-color: #000000;
    color: white;
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  @media screen and (min-width: $tablets-breakpoint) {
    height: 24px;
    width: 24px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    margin-top: 48px;
  }
}
