@import "../../styles/variables";

.container {
  display: flex;
  width: 520px;
  padding: 0 120px;
  flex-direction: column;
  margin-top: 50px;

  @media only screen and (max-width: $tablets-breakpoint) {
    width: 100%;
    padding: 0;
    margin-top: 32px;
    justify-content: center;
    align-items: center;
  }

  .socials {
    display: flex;

    @media only screen and (max-width: $tablets-breakpoint) {
      margin: 0 0 22px 0;
      justify-content: center;
    }

    .socialIcon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 8px;

      @media only screen and (max-width: $tablets-breakpoint) {
        margin-right: 16px;
      }
    }
  }

  .links {
    display: flex;
    margin-top: 12px;
    flex-wrap: wrap;

    @media only screen and (max-width: $tablets-breakpoint) {
      margin-bottom: 10px;
    }

    .link {
      display: flex;
      align-items: flex-start;
      padding: 0 16px 0 16px;
      height: 24px;
      border-left: 1px solid $dark-grey;
      cursor: pointer;

      @media only screen and (max-width: $tablets-breakpoint) {
        justify-content: center;
        padding: 0 30px 0 30px;
      }

      &:first-child {
        border-left: unset;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      p {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: $dark-grey-500;
        font-weight: 300;
        margin: 0;
        padding: 0;
      }
    }
  }
}
