@import '../../styles/variables';

// Keeping this file as not a module makes it a lot easier to overwrite video player's styles

$icon-play: "../../assets/player/play.svg";
$icon-pause: "../../assets/player/pause.svg";
$icon-enter-fullscreen: "../../assets/player/enterFullscreen.svg";
$icon-exit-fullscreen: "../../assets/player/exitFullscreen.svg";
$icon-progress-thumb: "../../assets/player/progressThumb.svg";
$icon-unmuted: "../../assets/player/unmuted.svg";
$icon-muted: "../../assets/player/muted.svg";

$play-control-size: 48px;
$bottom-controls-size: 40px;
$time-info-width: 67px;
$progress-bar-background: #737373;
$progress-bar-buffered-color: #AAAAAA;
$progress-bar-played-color: #FFFFFF;
$progress-clickable-area-height: 16px;
$progress-visible-area-height: 4px;
$progress-top-position: 8px;
$play-progress-height: 4px;
$play-progress-top-position: 8px;

$animation-easeInOutQuart-slow: 300ms cubic-bezier(0.77, 0, 0.175, 1);

@mixin control-button-shared($background-image) {
  cursor: pointer;
  .vjs-icon-placeholder:before {
    content: url($background-image);
  }
}

@mixin volume-button-size {
  width: 48px;
  height: 48px;
  border-radius: 32px;
}

@mixin fullscreen-control-before-shared() {
  .vjs-icon-placeholder:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
  }
}

.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-js, .video-js.not-hover {
  width: 100%;
  z-index: 0;
  border: 6px rgba(236, 28, 46, 1) solid;

  // Hide controls when big play button is visible
  &:not(.vjs-has-started) {
    .vjs-control-bar .vjs-controls-top {
      opacity: 0;
    }
  }

  // control bar styling - adding it here instead of placing these with oll the others SOMEHOW prevents progress bar from jumping up when user is inactive...
  .vjs-control-bar {
    transition: opacity $animation-easeInOutQuart-slow;
    z-index: 2;
    opacity: 1;
    background-color: transparent;
    height: auto;
    width: 100%;
    padding: 0 10px 20px;
    display: flex;
    flex-direction: column;

    .vjs-controls-top {
      width: 100%;
      height: $play-control-size;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      .vjs-button-progress-skip {
        justify-self: left;
        margin-left: 12px;
      }

      .vjs-button-progress-rewind {
        justify-self: right;
        margin-right: 12px;
      }

      .vjs-button-playlist-next {
        margin-left: 4px;
        @media screen and (min-width: $tablets-breakpoint) {
          margin-left: 12px;
        }
      }

      .vjs-button-playlist-previous {
        margin-right: 4px;
        @media screen and (min-width: $tablets-breakpoint) {
          margin-right: 12px;
        }
      }

      .vjs-play-control {
        margin: 0 12px;
        width: $play-control-size;
        height: $play-control-size;
      }
    }

    .vjs-controls-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .vjs-time-display {
        display: flex;
        min-width: 55px;
        margin: 4px 12px 0 0;
        justify-content: flex-end;

        @media only screen and ($tablets-breakpoint) {
          margin: 0 12px 0 0;
        }

        .vjs-current-time, .vjs-time-divider, .vjs-duration {
          display: block;
          color: white;
        }
        // styling for video time text - watched duration, total duration and slash between them
        .vjs-time-control {
          justify-self: center;
          color: white;
          padding: 0;
          width: auto;
          min-width: unset;
          height: fit-content;
          font-family: Poppins, sans-serif;
          line-height: 16px;
          font-size: 10px;

          @media screen and (min-width: $desktop-breakpoint) {
            margin: 5px 0 0;
          }

          &.vjs-current-time {
            margin-right: 2px;
          }
          &.vjs-duration {
            margin-left: 2px;
          }
        }
      }

      // progress bar
      .vjs-progress-control, .vjs-progress-control.vjs-user-inactive {
        // set opacity to 1 to prevent progress bar fadeout when user goes into fullscreen and moves cursor out of the player but still allows whole control bar to fade out
        // overwriting opacity with !important is necessary here or it's not taking effect
        align-self: center;
        width: auto;
        opacity: 1 !important;
        z-index: 0;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        position: relative;
        display: block;
        margin: 0 10px 4px 10px;
        padding: 0;
        height: $progress-clickable-area-height;
        border-radius: 4px;

        @media only screen and ($tablets-breakpoint) {
          margin: 0 10px 0 10px;
        }

        // Overwrite progress bar background
        .vjs-progress-holder {
          background: transparent;
          margin: 0;
          border-radius: 4px;
        }
        // overwrite hover positioning
        &:hover {
          top: unset;
          left: unset;
          right: unset;
          bottom: unset;
          position: relative;
          padding: 0;
          height: $progress-clickable-area-height;
          border-radius: 4px;
        }
        // Actually visible part of progress control bar
        &:after {
          content: '';
          width: 100%;
          display: block;
          background: $progress-bar-background;
          height: $progress-visible-area-height;
          position: absolute;
          top: $progress-top-position;
          border-radius: 4px;
        }
        // buffered progress bar
        .vjs-load-progress {
          background-color: $progress-bar-buffered-color;
          z-index: 100;
          height: $progress-visible-area-height;
          top: $progress-top-position;
          border-radius: 4px;
        }
        // Black cursor position indicator displayed over progress control bar
        .vjs-mouse-display {
          z-index: 100;
          height: $progress-visible-area-height;
          top: $progress-top-position;
          border-radius: 4px;
        }
        // progress indicator styling
        .vjs-play-progress {
          border-radius: 4px;
          z-index: 200;
          background-color: $progress-bar-played-color;
          height: $play-progress-height;
          // can't do without setting negative numbers
          top: $play-progress-top-position;
          // add vertical line svg at the end of progress bar
          &:before {
            // remove pointer events to prevent any interference of this dot in progress control behaviour
            pointer-events: none;
            position: absolute;
            // can't do without setting negative number
            top: -6px;
            right: -7px;
            content: url($icon-progress-thumb);
          }
        }
        .vjs-slider-bar {
          border-radius: 4px !important;
          &::after {
            border-radius: 4px;
          }
        }
      }

      .vjs-button-playback-rate {
        justify-self: center;
      }

      .vjs-fullscreen-control {
        align-self: flex-start;
        height: 100%;
      }
    }
  }

  .vjs-volume-panel {
    opacity: 1;
    z-index: 2;
    @include volume-button-size;
    position: absolute;
    top: 24px;
    left: 24px;
    background-color: black;
    display: flex;
    align-items: center;


    .vjs-mute-control {
      @include volume-button-size;
      .vjs-icon-placeholder:before {
        pointer-events: none;
        position: absolute;
        top: 8px;
        left: 7px;
        width: 32px;
        height: 32px;
      }
      &.vjs-vol-0 .vjs-icon-placeholder:before {
        content: url($icon-muted);
      }

      &:not(.vjs-vol-0) .vjs-icon-placeholder:before {
        content: url($icon-unmuted);
      }
    }

    &.vjs-volume-panel-horizontal.vjs-hover {
      width: 290px;
      & .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal {
        width: 211px;
        margin-left: 4px;
      }
    }

    .vjs-volume-horizontal {
      display: flex;
      align-items: center;
      .vjs-volume-bar {
        width: 100%;
        height: 6px;
        background-color: $dark-grey;
        border-radius: 4px;

        .vjs-volume-level {
          height: 6px;
          border-radius: 4px;
          &:before {
            // remove pointer events to prevent any interference of this dot in progress control behaviour
            pointer-events: none;
            position: absolute;
            // can't do without setting negative number
            width: 24px;
            height: 24px;
            top: -5px;
            right: -12px;
            content: url($icon-progress-thumb);
          }
        }
      }
    }
  }

  // vertical volume controls styling overwrite
  .vjs-volume-panel.vjs-volume-panel-vertical {
    @include volume-button-size;
    transition: height 1s;
    bottom: 120px;
    left: 16px;
    top: unset;
    display: block;
    overflow: hidden;

    .vjs-mute-control {
      @include volume-button-size;
      position: absolute;
      background-color: black;
      z-index: 100;
      bottom: 0;
      left: 0;
      top: unset;
      .vjs-icon-placeholder:before {
        transform: scale(0.75);
        margin-left: 2px;
      }
    }

    .vjs-volume-control {
      background-color: transparent;
      height: 101px;
      margin: 0;
      padding: 0;
      top: 0;
      bottom: unset;
      left: 9px;
      opacity: 1;
    }
    &.vjs-hover {
      height: 190px;
      & .vjs-mute-control ~ .vjs-volume-control {
        background-color: transparent;
        margin: 0;
        top: 24px;
        bottom: unset;
        left: 9px;
      }
    }

    .vjs-volume-vertical {
      display: flex;
      align-items: center;
      position: relative;
      .vjs-volume-bar {
        width: 4px;
        height: 101px;
        background-color: $dark-grey;
        border-radius: 4px;
        .vjs-mouse-display, .vjs-volume-tooltip {
          display: none;
        }

        .vjs-volume-level {
          width: 4px;
          border-radius: 4px;
          &:before {
            // remove pointer events to prevent any interference of this dot in progress control behaviour
            //pointer-events: none;
            position: absolute;
            // can't do without setting negative number
            top: -5px;
            left: -6px;
            content: url($icon-progress-thumb);
            transform: scale(0.75);
          }
        }
      }
    }
  }

}

.video-js {

  .vjs-big-play-button, &:hover .vjs-big-play-button{
    background-color: transparent;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 2;
    background-image: url($icon-play);
    background-repeat: no-repeat;
    background-size: 64px 64px;
    border: none !important;
    box-shadow: none !important;
    opacity: 1;
    &:before, .vjs-icon-placeholder:before {
      content: "";
      display: none;
    }
    &:hover {
      background-color: transparent;
    }
  }

  .vjs-button.vjs-control.vjs-play-control {
    // .vjs-paused and .vjs-playing doesn't apply before player loads metadata and starts playing so we just display our play button as fallback instead standard one
    // this is to prevent weird components jumps and showing standard button which looks bad next to our styled components
    @include control-button-shared($icon-play);
    // play button styling
    &.vjs-paused {
      @include control-button-shared($icon-play);
    }
    // pause button styling
    &.vjs-playing {
      @include control-button-shared($icon-pause);
    }
  }

  // enter fullscreen icon styling
  & .vjs-fullscreen-control {
    @include control-button-shared($icon-enter-fullscreen);
    @include fullscreen-control-before-shared;
  }

  //exit fullscreen icon styling
  &.vjs-fullscreen .vjs-fullscreen-control {
    @include control-button-shared($icon-exit-fullscreen);
    @include fullscreen-control-before-shared;
  }
}

// Gradient styling
.vjs-controls-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
  opacity: 1;
  transition: opacity $animation-easeInOutQuart-slow;
  // Make sure it does not block clicking/tapping on player to play/pause
  pointer-events: none;
}


// styling for control bar for each of possible activity scenarios
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
.video-js.vjs-has-started.vjs-paused.vjs-ad-playing.vjs-user-inactive .vjs-control-bar,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
  // fully hide the control bar and change animation timing to our standard one
  opacity: 0;
  transition: opacity $animation-easeInOutQuart-slow;
  // A hack which allows us to easily synchronise gradient visibility with all states of control bar visibility
  &+.vjs-controls-gradient, &+.vjs-controls-gradient+.vjs-volume-panel {
    opacity: 0;
    transition: opacity $animation-easeInOutQuart-slow;
  }
  //Uncomment line below to stop control bar hiding if needed for styling
  //opacity: 1;
  //&+.vjs-controls-gradient, &+.vjs-controls-gradient+.vjs-volume-panel {
  //  opacity: 1;
  //}
}
